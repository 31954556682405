<template>
  <div class="static-seo">
    <!-- Remove modal -->
    <CModal
      :title="modal.title"
      color="danger"
      :show.sync="modal.show"
      :centered="true"
    >
      {{ modal.message }}
      <template #footer>
        <button type="button" class="btn btn-secondary" @click="closeModal">
          Cancelar
        </button>
        <button type="button" class="btn btn-danger" @click="remove">
          Remover
        </button>
      </template>
    </CModal>

    <CForm @submit.prevent="send">
      <CRow>
        <CCol col="12">
          <CCard class="border-0">
            <!-- Title -->
            <CCardHeader>
              <CRow class="align-items-center">
                <CCol sm="12" md="6">
                  <h4 class="mb-0 card-title">
                    SEO (Search Engine Optimization)
                  </h4>
                </CCol>
                <CCol sm="12" md="6" class="card-header-actions">
                  <CButton
                    v-if="items.length > 0"
                    color="danger"
                    class="float-right ml-2"
                    @click="removeModal(null)"
                  >
                    <CIcon name="cil-trash" class="mr-1 my-0" />
                    <span style="padding-top: 2px">Remover páginas</span>
                  </CButton>
                  <CButton color="primary" class="float-right" @click="addItem">
                    <CIcon name="cil-library-add" class="mr-2" />
                    Adicionar página
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>

            <CCardBody>
              <!-- Content -->
              <CRow v-if="items.length > 0">
                <CCol
                  v-for="(item, index) in items"
                  :key="item[index]"
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <CCard class="card-static-seo">
                    <CCardHeader>
                      <CRow class="align-items-center">
                        <CCol>
                          <b class="mb-0"> Detalhes da página </b>
                        </CCol>
                        <CCol>
                          <div class="card-header-actions">
                            <a
                              v-c-tooltip="'Remover'"
                              href="javascript:void(0)"
                              class="d-flex align-items-center text-danger"
                              placement="top"
                              @click="removeModal(index)"
                            >
                              <CIcon name="cil-trash"> </CIcon>
                            </a>
                          </div>
                        </CCol>
                      </CRow>
                    </CCardHeader>
                    <CCardBody>
                      <CRow>
                        <CCol sm="12">
                          <CInput
                            v-model="item.url"
                            label="URL de referência"
                            placeholder="Ex: /institucional"
                            :add-input-classes="`input-url-${index}`"
                            :required="true"
                          />
                        </CCol>
                        <CCol sm="12">
                          <CInput
                            v-model="item.title"
                            label="Título da página"
                            placeholder="Digite o título da página"
                            :description="`${item.title.length}/${titleLength} caracteres`"
                            :maxlength="titleLength"
                            :required="true"
                          />
                        </CCol>
                        <CCol sm="12">
                          <CTextarea
                            v-model="item.description"
                            label="Descrição da página"
                            placeholder="Digite a descrição da página"
                            class="mb-0"
                            :description="`${item.description.length}/${descriptionLength} caracteres`"
                            :maxlength="descriptionLength"
                          />
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>

              <!-- No content -->
              <p v-else class="mb-0 text-muted">
                Não há páginas cadastradas,
                <a href="javascript:void(0)" class="text-info" @click="addItem"
                  >clique aqui</a
                >
                para adicionar uma nova página.
              </p>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol class="mt-3 mb-5">
          <div class="d-flex justify-content-end align-items-center">
            <CButton color="success" type="submit" :disabled="processing">
              {{ processing ? 'Salvando...' : 'Salvar' }}
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CForm>
  </div>
</template>

<script>
import SeoService from '@/services/seo.service'

export default {
  metaInfo: {
    title: 'SEO',
    titleTemplate: ' %s - Manager - OW Interactive'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_seo')) {
        vm.$router.push('/404')
      }
    })
  },

  data() {
    return {
      items: [],
      titleLength: 70,
      descriptionLength: 160,
      pageToRemove: null,
      modal: {
        show: false,
        title: '',
        message: ''
      },
      processing: false
    }
  },

  async created() {
    const allPages = await SeoService.listAll()

    const storedSeo = allPages.map((page) => {
      return {
        url: page.url,
        description: page.description,
        title: page.title
      }
    })

    this.items = storedSeo
  },

  methods: {
    addItem() {
      this.items.unshift({
        url: '',
        title: '',
        description: ''
      })

      setTimeout(() => {
        const firstInputUrl = document.querySelector('.input-url-0')
        firstInputUrl.focus()
      }, 500)
    },

    removeModal(page) {
      this.pageToRemove = page
      this.modal = {
        show: true,
        title:
          this.pageToRemove !== null
            ? 'Remover página'
            : 'Remover todas as páginas',
        message:
          this.pageToRemove !== null
            ? 'Ao confirmar essa ação a página não poderá ser recuperada, tem certeza que desejar continuar?'
            : 'Ao confirmar essa ação as páginas não poderão ser recuperadas, tem certeza que desejar continuar?'
      }
    },

    remove() {
      if (this.pageToRemove !== null) {
        this.items.splice(this.pageToRemove, 1)
        this.pageToRemove = null
        this.closeModal()
      } else {
        this.items = []
        this.closeModal()
      }
    },

    closeModal() {
      this.modal = {
        show: false,
        title: '',
        message: ''
      }
    },

    async send() {
      this.processing = true
      const response = await SeoService.setSeo(this.items)

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: 'Seo de paginas salvas com sucesso!'
        })
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Erro ao salvar o Seo de paginas, tente novamente mais tarde.'
        })
      }
      this.processing = false
    }
  }
}
</script>

<style lang="scss">
.card-static-seo {
  textarea {
    height: 80px !important;
  }
}

@media only screen and (max-width: 768px) {
  .static-seo {
    .card {
      .card-title {
        margin-bottom: 1em !important;
      }

      .card-header-actions {
        button {
          float: none !important;
          margin-left: 0px !important;
          margin-right: 0.5rem !important;

          &:last-child {
            margin-right: 0px !important;
          }
        }
      }
    }
  }
}
</style>
