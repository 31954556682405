import axios from './axios.service'

const method = 'seo'

class SeoService {
  async listAll() {
    try {
      const response = await axios.get(`/${method}`)
      return response.data
    } catch (error) {
      return error
    }
  }

  async setSeo(allPages) {
    try {
      const response = await axios({
        method: 'post',
        url: `/${method}`,
        data: {
          allPages
        }
      })
      return response
    } catch (error) {
      return error
    }
  }
}

export default new SeoService()
